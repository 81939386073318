<template>
  <div>
    <div class="top">
      <div class="avatar">
        <div class="imgbox">
          <router-link :to="{'path':'/addhead'}"> 
            <img style="width: 23vw;" v-if="this.photo" :src="this.photo"/>
            <img style="width: 23vw;" v-else src="../../assets/img/nohead.png" alt="">
          </router-link> 
        </div>
        <div class="avatar-font">
          <p class="p1">{{ this.user_name }}</p>
          <p class="p2" v-if="this.mobile">{{ this.mobile }}</p>
          <p class="p2" v-else>暂无获取到手机号</p>
        </div>
          <img class="top-img" src="../../assets/img/fengcai.png" alt="">
      </div>
      <Datepicker class="time"></Datepicker>        
    </div>

    <div class="card">
      <p class="title">到校汇总</p>
      <div class="card-1">
        <p class="card-p"><img src="@/assets/img/orange.png">&nbsp;{{ this.$route.params.class_name }}</p>
        <table>
            <tr>
                <th>姓名</th>
                <th>打卡时间</th>
                <th>状态</th>
                <th>体温</th>
            </tr>
            <template style="border: 1px solid red;">
            <tr v-for="(item,index) in list" :key="index">
                <td>{{ item.user_name }}</td>
                <td>{{ item.attendance_time }}</td>
                <td>{{ item.status }}</td>
                <td>{{ item.temperature }}</td>
            </tr>
          </template>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
import { teacherDetail } from '../../api/teacher/index'
import Datepicker from "../../components/Datepicker.vue";
import Foot from "../../components/Foot.vue";
import {getUserName, getUserMobile, getUserPhoto} from "../../utils/user";

export default {
  components: { Foot, Datepicker },
  data() {
    return {
        temp:{
          class_id: this.$route.params.class_id,
          datetime: this.$route.params.datetime,
        },
        list: [],
        user_name: '',
        mobile: '',
        photo: ''
    };
  },
  created(){
    this.user_name = getUserName();
    this.mobile = getUserMobile();
    this.photo = getUserPhoto();
    this.getlist()
  },
  methods: {
    getlist(){
      teacherDetail(this.temp).then(res => {
        console.log(res);
        this.list = res.data
      })
    },
    styleBack({ row, column, rowIndex, columnIndex }) {
      if(row.state == '——'){
        return { backgroundColor: "#ff795b20"};
      }else if(row.state == '迟到'){
        return { backgroundColor: "#ffb45220" };
      }
      // if (columnIndex == 1 && row.num > 3) {
      //   return { backgroundColor: "#0c87ef" };
      // }else if(columnIndex == 1){
      //  return { backgroundColor: "#FDD56A" };
      // }
    },

  },
};
</script>

<style lang="less" scoped>
  .top-img{
    width: 22vw;
    position: absolute;
    right: 4vw;
    top: 15vw;
  }
/* 蓝色背景图 */
.top {
  background: url("../../assets/img/Group 427319265.png");
  background-repeat: no-repeat;
  background-size: 100% 100%; // 等比例拉伸
  widows: 100%;
  height: 13rem;
}
/* 头像框 */
.avatar {
  .imgbox {
    width: 23vw;
    height: 23vw;
    border-radius: 54px;
  }
  .avatar-font {
    margin-left: 2vw;
    width: 34vw;
    .p1{
      color: white;
      font-weight: bold;
      font-size: 5vw;
    }
    .p2{
      color: #ffffffba; 
      font-size: 3.5vw;
      margin-top: -1.5vh;
    }
  }
  display: flex;
  padding: 36px 0 0 18px;
  align-items: center;
}

//卡片标题
.title {
  font-size: 5vw;
  font-weight: bold;
}
//卡片展示
.card {
  margin-top: 7rem;
  width: 90%;
  margin-left: 50%;
  transform: translate(-50%);
}
.card-1{
  border-radius: 15px;
  padding-top:.1rem ;
  padding-bottom: .5rem;
  box-shadow: 0 1px 5px rgb(228, 227, 227);
  margin-bottom: 1rem;
}
.card-p {
  font-size: 1.1rem;
  font-weight: bold;
  padding: 0rem 0rem 0rem 1rem;
  display: flex;

  img{
    width: 4%;
    height: 4%;
    margin-top: .2rem;
  }
}
.table{
  margin-bottom: .5rem;
  border: 1px solid rgb(255, 255, 255);
}
table{
  width: 100%;

  td{
    text-align: left;
  }
  th{
    width: 25%;
    text-align: left;
    padding-left: 5vw;
    height: 4vh;
    font-size: 3.5vw;
    color: #999999;
    font-family: '微软雅黑';
  }
  td{
    width: 25%;
    text-align: left;
    padding-left: 5vw;
    height: 4.5vh;
    font-size: vw;
    color: #666666;
    font-family: '宋体正文';
  }
}
 
</style>
