import request from '@/utils/request'
// 获取列表
export function teacherIndex(query) {
  return request({
    url: '/mobile/attendance/index',
    method: 'get',
    params: query
  })
}
// 获取列表详情
export function teacherDetail(query) {
  return request({
    url: '/mobile/attendance/detail',
    method: 'get',
    params: query
  })
}